<template>
  <div class="empty-state pt-0 row align-items-stretch">
    <div class="col-md-6">
      <div class="px-3 py-3">
        <div class="empty-state__sign-ot-week__header">
          <font-awesome-icon class="empty-state__sign-ot-week__header__icon mr-2"
                             :icon="['fas', 'sign-language']" />
          <span class="empty-state__sign-ot-week__header__text">{{ $t('empty_state.sign_ot_week') }}</span>
        </div>
        <div class="divider divider--light my-1"></div>
        <div v-if="loading"
             class="d-flex justify-content-center py-4">
          <b-spinner />
        </div>
        <SearchResultCard v-if="signOfTheWeek"
                          class="mt-4 mx-xl-5 px-lg-5"
                          :searchResult="signOfTheWeek" />
      </div>
    </div>
    <div class="col-md-6 empty-state__call-to-action d-flex flex-column justify-content-center align-items-center py-5">
      <span class="empty-state__call-to-action__text empty-state__call-to-action__text--supertext">
        {{ $t('empty_state.find_other_sign') }}
      </span>
      <EmptyStateImage class="mt-n4" />
      <span class="d-none d-md-block empty-state__call-to-action__text empty-state__call-to-action__text--subtext">
        {{ $t('empty_state.subtext') }}
      </span>
      <span class="d-md-none empty-state__call-to-action__text empty-state__call-to-action__text--subtext">
        {{ $t('empty_state.subtext_mobile') }}
        <font-awesome-icon class="empty-state__call-to-action__text__menu fa-2x"
                           :icon="['fal', 'bars']" />
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import EmptyStateImage from '@/assets/svg/emptystate.svg';
import { Component, Vue } from 'vue-property-decorator';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { signService } from '../../shared/services/SignService';
import { axiosHttp } from '../../util/AxiosHttp';
import SearchResultCard from '../search/SearchResultCard.vue';

@Component({ components: { EmptyStateImage, SearchResultCard } })
export default class EmptyState extends Vue {
    public signOfTheWeek: ISignOverview | null = null;

    public loading = false;

    public async created(): Promise<void> {
        this.loading = true;
        this.signOfTheWeek = await signService.getSignOfTheWeek();
        this.loading = false;
    }
}
</script>

<style lang="scss">
.empty-state {
    border: $thin-border-size solid $brand-color;
    border-radius: $default-border-radius;
    color: $background-text-color;
}

.empty-state__sign-ot-week__header {
    color: $brand-color;
    font-size: 1.5rem;
}

.empty-state__call-to-action {
    background-color: $header-color;
    color: $header-text-color;
}

.empty-state__call-to-action__text {
    font-family: $font-family-accent;
    text-align: center;
    letter-spacing: 0.05rem;
    max-width: 12.5rem;

    &--supertext {
        font-weight: bold;
    }

    &--subtext {
        font-size: $font-size-slightly-smaller;
    }
}

.empty-state__call-to-action__subtext {
    font-family: $font-family-accent;
    font-size: $font-size-slightly-smaller;
    text-align: center;
    letter-spacing: 0.05rem;
    max-width: 12.5rem;
}

.empty-state__call-to-action__text__menu {
    border: $thin-border-size solid $header-text-color;
    border-radius: $default-border-radius;
    padding: 0.15rem;
    vertical-align: top;
}
</style>
